import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { FormEvent, useState } from 'react';
import { AccessLevel, UserInfo, UserType, allLevels, getAccessLevelFromString } from '../../components/AppConstants';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { ButtonGroupControl } from '../../components/custom/ButtonGroupControl';
import { CheckboxList } from '../../components/custom/CheckboxList';
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';
import { TextFieldOptional } from '../../components/custom/TextFieldOptional';
import { TextFieldRequired } from '../../components/custom/TextFieldRequired';
import { SStackForm } from '../../components/styled/stack/SStackForm';
import { SStackWhite } from '../../components/styled/stack/SStackWhite';

interface EditUserProps {
  userInfo: UserInfo
  onBackClick: () => void
  onDeleteClick: (userInfo: UserInfo) => void
  onUserUpdated: (userInfo: UserInfo) => void
  logout: () => void
}

export function EditUser(props: EditUserProps) {
  const [userName, setUserName] = useState<string>(props.userInfo.name);
  const [userEmail, setUserEmail] = useState<string>(props.userInfo.email);
  const [userTamilName, setUserTamilName] = useState<string>(props.userInfo.tamilName);
  const [userType, setUserType] = useState<string>(props.userInfo.type);
  const [library, setLibrary] = useState<AccessLevel>(getAccessLevelFromString(props.userInfo.libraryAccess));
  const [studentInfo, setStudentInfo] = useState<AccessLevel>(getAccessLevelFromString(props.userInfo.studentInfoAccess));
  const [year, setYear] = useState<string[]>(props.userInfo.year);
  const [level, setLevel] = useState<string[]>(props.userInfo.level);
  const [userAccess, setUserAccess] = useState<string>(props.userInfo.userAccess);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  function onSubmitHandler(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    props.onUserUpdated({
      id: props.userInfo.id,
      name: userName,
      email: userEmail,
      tamilName: userTamilName,
      type: userType,
      year: year,
      level: level,
      allLevels: level.length === allLevels.length,
      libraryAccess: library,
      studentInfoAccess: studentInfo,
      userAccess: userAccess,
      isTest: props.userInfo.isTest
    })
  }

  return (
    <SStackForm onSubmit={onSubmitHandler}>
      <SnackbarProvider />
      <SStackWhite>
        <TextFieldRequired label='Name' value={userName} baseValue={props.userInfo.name} onChange={setUserName} />
        <TextFieldOptional label='Email' value={userEmail} baseValue={props.userInfo.email} onChange={setUserEmail} />
        <TextFieldOptional label='Tamil Name' value={userTamilName} baseValue={props.userInfo.tamilName} onChange={setUserTamilName} />
        <ButtonGroupControl
          label='User Type'
          value={userType}
          baseValue={props.userInfo.type}
          options={Object.keys(UserType)}
          onChange={setUserType} />
        <ButtonGroupControl
          label="Library"
          value={library}
          baseValue={getAccessLevelFromString(props.userInfo.libraryAccess)}
          options={Object.keys(AccessLevel)}
          onChange={newValue => setLibrary(getAccessLevelFromString(newValue))} />
        <ButtonGroupControl
          label="Student Info"
          value={studentInfo}
          baseValue={getAccessLevelFromString(props.userInfo.studentInfoAccess)}
          options={Object.keys(AccessLevel)}
          onChange={newValue => setStudentInfo(getAccessLevelFromString(newValue))} />
        <ButtonGroupControl
          label="User Management"
          value={userAccess}
          baseValue={getAccessLevelFromString(props.userInfo.userAccess)}
          options={Object.keys(AccessLevel)}
          onChange={newValue => setUserAccess(getAccessLevelFromString(newValue))} />
        <Box border={1} borderRadius={1} borderColor='rgba(0, 0, 0, 0.23)'>
          <CheckboxList
            label='All Classes'
            allValues={allLevels}
            selectedValues={level}
            onItemChange={(value, checked) => {
              setLevel(checked ? level.concat(value) : level.filter(item => item !== value))
              if (checked && studentInfo === AccessLevel.NoAccess) {
                setStudentInfo(AccessLevel.ReadOnly)
              }
            }}
            onAllChange={(checked) => {
              setLevel(checked ? allLevels : [])
              if (checked && studentInfo === AccessLevel.NoAccess) {
                setStudentInfo(AccessLevel.ReadOnly)
              }
            }} />
        </Box>
      </SStackWhite >
      <BottomNavBar>
        <ConfirmationDialog
          open={showConfirmationDialog}
          message={`Are you sure you want to delete '${props.userInfo.name}'?`}
          onConfirm={() => props.onDeleteClick(props.userInfo)}
          onCancel={() => setShowConfirmationDialog(false)} />
        <BottomNavAction label="Back" icon={<ArrowBackIosNewIcon />} onClick={props.onBackClick} />
        {props.userInfo.id !== -1 && <BottomNavAction label="Delete" icon={<DeleteForeverIcon />} onClick={() => setShowConfirmationDialog(true)} />}
        <BottomNavAction label="Save" icon={<SaveIcon />} type='submit' />
      </BottomNavBar>
    </SStackForm>
  )
}