import React, { useCallback, useEffect, useState } from 'react';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/login/LoginPage';
import { UserInfo } from './components/AppConstants';
import { Backdrop, CircularProgress } from '@mui/material';
import InstallButton from './pages/InstallButton';

export function App() {
  const [backdrop, setBackdrop] = useState(false);
  const [user, setUser] = useState<UserInfo | undefined>(undefined)

  const loadUser = useCallback(
    async () => {
      try {
        setBackdrop(true)
        const response = await fetch("/api/users/me");
        if (response.ok) {
          const result: UserInfo = await response.json();
          setUser(result)
          console.log("Logged in user: " + result.name)
          console.log("Email: " + result.email)
        } else {
          console.log("No user logged in.")
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
      setBackdrop(false)
    }, [])

  useEffect(() => {
    loadUser();
  }, [])

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Show a different page if the user doesn't have access to any level */}
      {user && user.email.length > 0 ? <HomePage userInfo={user} logout={() => setUser(undefined)} /> : <LoginPage />}
    </React.Fragment>
  )
}
