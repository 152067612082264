import React, { useState, useEffect } from 'react';

declare global {
  interface WindowEventMap {
    beforeinstallprompt: CustomEvent<BeforeInstallPromptEvent>;
    appinstalled: Event;
  }
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

function InstallButton() {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallable, setIsInstallable] = useState(false);
  console.log('InstallButton rendered');

  useEffect(() => {
    const handler = (e: Event) => {
      console.log('beforeinstallprompt fired');
      const promptEvent = e as CustomEvent<BeforeInstallPromptEvent>;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      promptEvent.preventDefault();
      // Save the event so it can be triggered later.
      setDeferredPrompt(promptEvent.detail);
      // Update UI to notify the user they can install the PWA
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const handleInstallClick = () => {
    console.log('Install button clicked');
    if (deferredPrompt) {
      console.log('Prompting user to install');
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        // Clear the saved prompt
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    window.addEventListener('appinstalled', () => {
      console.log('App installed');
      // Hide the install button
      setIsInstallable(false);
      setDeferredPrompt(null);
    });
  }, []);

  return (
    <>
      {isInstallable && (
        <button onClick={handleInstallClick}>Install App</button>
      )}
    </>
  );
}

export default InstallButton;