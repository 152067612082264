// Constants
export const drawerWidth = 240;
export const defaultDateFormat = "yyyy-MM-dd";
export const allLevels = [
  "MunMazhalai",
  "Mazhalai",
  "Elementary 1",
  "Elementary 2",
  "Elementary 3",
  "Elementary 4",
  "Elementary 5",
  "Intermediate 1A",
  "Intermediate 1B",
  "Intermediate 1C",
  "Advanced 2A",
  "Advanced 2B",
  "Uyarnilai",
];

// Interfaces
export interface UserInfo {
  id: number;
  name: string;
  email: string;
  tamilName: string;
  type: string;
  year: string[];
  level: string[];
  allLevels: boolean;
  studentInfoAccess: string;
  libraryAccess: string;
  userAccess: string;
  isTest: boolean;
}

export interface ExamPattern {
  id: number;
  name: string;
  attendancePercentage: number;
  homeworkPercentage: number;
  categories: ExamPatternCategoryGroup;
  levels: string[];
}

export interface ExamPatternCategoryGroup {
  ClassPerformance: ExamPatternCategory[];
  Project: ExamPatternCategory[];
  Exam: ExamPatternCategory[];
}

export interface ExamPatternCategory {
  id: number;
  examPatternId: number;
  categoryType: string;
  categoryName: string;
  categoryMax: number;
  categoryPercentage: number;
}

// export interface ExamCategoryScoreGroup {
//   classPerformance: ExamCategoryScore[];
//   project: ExamCategoryScore[];
//   exam: ExamCategoryScore[];
// }

export interface ExamScore {
  id: number;
  examPatternId: number;
  schoolYear: string;
  dayId: number;
  day: string;
  studentId: number;
  studentName: string;
  level: string;
  fatherEmail: string;
  motherEmail: string;
  attendancePercentage: number;
  attendanceDays: number;
  attendancePresent: number;
  attendanceScore: number;
  homeworkPercentage: number;
  homeworkDays: number;
  homeworkCompleted: number;
  homeworkScore: number;
  categoryScores: ExamCategoryScore[];
  total: number;
  comments: string;
  emailSent: boolean;
}

export interface ExamCategoryScore {
  id: number;
  examScoreId: number;
  examPatternCategoryId: number;
  categoryType: string;
  categoryName: string;
  categoryMax: number;
  categoryPercentage: number;
  score: number;
}

// Enums
export enum UserType {
  PTC = "PTC",
  Teacher = "Teacher",
  Volunteer = "Volunteer",
}

export enum AccessLevel {
  ReadOnly = "ReadOnly",
  ReadWrite = "ReadWrite",
  NoAccess = "NoAccess",
}

export enum FeeOptions {
  Paid = "Paid",
  NotPaid = "NotPaid",
}

export enum BooksOptions {
  Collected = "Collected",
  NotCollected = "NotCollected",
}

export enum PrimaryContactOptions {
  Father = "Father",
  Mother = "Mother",
}

export enum GenderOptions {
  Male = "Male",
  Female = "Female",
}

export function getAccessLevelFromString(value: string): AccessLevel {
  return AccessLevel[value as keyof typeof AccessLevel];
}

export enum PageName {
  Students = "Students",
  Attendance = "Attendance",
  Homework = "Homework",
  Library = "Library",
  Exam = "Exam",
  ExamSetup = "ExamSetup",
  ExamSummary = "ExamSummary",
  Users = "Users",
  Log = "Log",
  AllStudents = "AllStudents",
  TeacherAttendance = "TeacherAttendance",
}

export function getPageNameFromString(value: string): PageName {
  return PageName[value as keyof typeof PageName];
}

export enum IconType {
  Male = "Male",
  Female = "Female",
}

export function isValidEmail(email: string): boolean {
  // Regular expression to match a valid email address
  const emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
}

export interface ExamSummaryReport {
  level: string;
  termData: TermData[];
}

export interface TermData {
  term: string;
  scoreAdded: boolean;
  emailSent: boolean;
}
